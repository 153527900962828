$('[data-toggle="menu"]').click(function(){
	var target= $(this).data('target');
	var bgMenuOpened = document.createElement('div');

	bgMenuOpened.className = 'bg-menu';

	bgMenuOpened.onclick = function(){
		$(target).removeClass('open');
		$('html, body').removeClass('lock-scroll');

		$(this).fadeOut(600, function(){
			this.remove()
		})
	};

	document.querySelector(target).appendChild(bgMenuOpened);

	$(bgMenuOpened).fadeIn(600);

	$(target).addClass('open');
	$('html, body').addClass('open');
});