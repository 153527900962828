$('[data-toggle-class]').click(function(){
	var classe = $(this).data('toggle-class');

	$(this).toggleClass(classe);
});

$('[data-qtde]').click(function(){
	var qtde = $(this).data('qtde');
	var pai = $(this).parents('form');

	$(this).siblings('li').removeClass('active');
	$(this).addClass('active');

	pai.find('.qnt-selecionada').text(qtde);
	pai.find('input').val(qtde);
});

$('[data-toggle="categorias"]').click(function(){
	var target = $(this).attr('data-target');
	var bgCategorias = document.createElement('div');
	var toggleCategoria = function(){
		$(target).toggleClass('open');
		$('body, html').addClass('lock-scroll');
	};

	bgCategorias.className = 'bg-categorias';

	bgCategorias.addEventListener('click', function(){
		toggleCategoria(target);
		this.remove();
	});

	document.querySelector(target).appendChild(bgCategorias);
	toggleCategoria(target);
});